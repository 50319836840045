import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../../components/layout'
import SEO from '../../components/seo'
import '../../components/antd/less/antMotionStyle.less'
import productFrame from './../../images/solution2.jpg'
import productModule from './../../images/solution3.jpg'

export default class SolutionList extends React.PureComponent {
  render() {
    const {
      data: { strapi = {} },
    } = this.props
    const { products, productGroups = [] } = strapi

    return (
      <Layout
        curIndex={3}
        products={products}
        productGroups={productGroups}
        showFooter={true}
      >
        <SEO title='解决方案' />
        <div className='core-tech-wrap solution-wrap'>
          <div className='main-img'></div>

          <div className='main-con'>
            <h2>产品概述</h2>
            <p className='description-text'>
              茶企管理系坑以先进的人工智能+区次谯技术为基础，沟建可信衣业的产业数字化平台.该系统为每一饼普洱茶从生产、力辽到销售、流通的全生命周期提供追溯及务；系统为每一个茶企单独部詈,同时也是每个茶企链接茶校云的3!要组件，它与茶蚊云中的节点构成基于区块链技术的茶皎链.茶企管理是一款基于web和微信生态的管理信息系统，主要协助茶企完成茶叶管理、茶饼转移、商品上聚、经销商管理、经销商订货、订单苜理、数据统计等工作，并可以为茶企各部门进行权限筲理.
            </p>
            <p className='description-text'>
              系统核心技术为基于机器学习和图像分析的方法，使得系统能在系统化流水线自动学习茶饵茶蚊的耀特征、深度纹理的相似性和差异性.“茶奴云”构建了用于保存茶蚊特征、茶饼信息、质检信息、茶饼流通等区块链技术——茶奴链.消费者通过扫描茶饼包装上二维码，即可获得茶窗的所有主要信息，包恬产地、原料、由量、生产日期、生产许可证、流通渠道等等.消费需通过上传条讲的照片，即可直验读茶饼是否与包装上的二维码相
              致，即“茶一纹一码”，彻底杜绝了H改包装等造便行为.系统实现了茶饼本体的溯源，使茶饼成为可信产品，并再此基础上
              ,加上可信的智能仓储技术，为金航服务提供了可能.通过技术创新,推动数字云南、可信农业的快速发展.
            </p>
            <div className='trace-source'>
              <img src={productFrame} alt='产品框架图' />
            </div>
          </div>
        </div>
        <div className='core-tech-wrap solution-wrap solution-module'>
          <div className='main-con'>
            <h2>系统模块功能</h2>

            <div className='trace-source'>
              <img src={productModule} alt='系统模块功能' />
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}
export const query = graphql`
  query {
    strapi {
      solutions {
        id
        title
        image_link
        short_description
      }
      products {
        id
        title
        product_group {
          code
          name
        }
        type
      }
      productGroups {
        id
        code
        name
        type
      }
    }
  }
`
